<template>
  <div class="bill-page">
    <div>
      <img src="./images/bill-1.png" alt="">
    </div>
    <div>
      <img src="./images/bill-2.png" alt="">
    </div>
    <div>
      <img src="./images/bill-3.png" alt="">
    </div>
  </div>
</template>
<script>
export default {
  name: 'IntroBill',
  created() {
    document.title = '共享账本使用说明';
  },
};
</script>
<style scoped lang="scss">
  .bill-page {
    padding-bottom: 25px;
    background-color: rgb(245, 245, 246);
    img {
      width: 100%;
    }
  }
</style>
